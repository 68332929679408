<template>
  <v-container fluid>
    <v-dialog :value="true" width="50%">
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-toolbar-title>

            {{  $t("home.passwordChange.title") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="d-flex flex-column align-center pa-4">

          <p class="text-center mt-4">{{ $t("home.passwordChange.text1", {min: 8})}}</p>
          <p class="text-center mt-4">{{ $t("home.passwordChange.text2", {min: 8})}}</p>

          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="isHeightCharValid" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("home.passwordChange.validation.atLeastXChars", {min: 8})}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="isOneUpperCaseValid" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("home.passwordChange.validation.atLeastXMaj", {min: 1})}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="isOneLowerCaseValid" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("home.passwordChange.validation.atLeastXMin", {min: 1})}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="isOneNumberValid" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("home.passwordChange.validation.atLeastXNum", {min: 1}) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-form ref="formPassword" class="formPassword">

              <v-text-field :type="type2" name="new-password" filled
                            :label="$t('account.fields.newPassword')" :rules="ValidationRules.password"
                            v-model="newPassword"
              >
                <template v-slot:append>
                  <v-btn icon @mousedown="type2 = 'text'" @mouseup="type2 = 'password'">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field :type="type3" name="new-password" filled
                            :label="$t('account.fields.confirmPassword')"
                            v-model="confirmNewPassword"
                            :error-messages="isSameAsNewPassword"
              >
                <template v-slot:append>
                  <v-btn icon @mousedown="type3 = 'text'" @mouseup="type3 = 'password'">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

            <v-container fluid class="d-flex flex-row align-center justify-center">
              <v-btn color="success" @click="savePassword">{{ $t("actions.save") }}</v-btn>
              <v-btn @click="$router.replace({name:'Home'})" class="my-4" text>{{ $t('actions.cancel') }}</v-btn>
            </v-container>

          </v-form>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ValidationRules from "@/Mixins/ValidationRules"
import UserService from "@/Services/UserService";
import CryptoJS from "crypto-js";

export default {
  name: "PasswordExpiration",
  mixins: [ValidationRules],
  computed: {
    isHeightCharValid() {
      let v = this.newPassword
      return v && v.length >= 8
    },
    isOneLowerCaseValid() {
      let v = this.newPassword
      return v && (v.match(/[a-z]/g))
    },
    isOneUpperCaseValid() {
      let v = this.newPassword
      return v && (v.match(/[A-Z]/g))
    },
    isOneNumberValid() {
      let v = this.newPassword
      return v && (v.match(/[0-9]/g))
    },
    isSameAsNewPassword() {
      return this.newPassword == this.confirmNewPassword ? "" : this.$t("account.fields.passwordDifferent")
    }
  },
  data() {
    return {
      newPassword: "",
      confirmNewPassword: "",
      type2: "password",
      type3: "password"
    }
  },
  methods: {
    savePassword: async function () {
      if (this.$refs.formPassword.validate()) {
        if (this.newPassword == this.confirmNewPassword) {
          let response = await UserService.postUser({
            password: this.newPassword.toString()
          })
          if (response) {
            if (response == "changingPasswordNeedAnotherPassword") {
              this.$nError(this.$t('account.messages.passwordIdentical'))
            } else {
              this.$nSuccess(this.$t('account.messages.passwordUpdated'))
              await this.$router.replace({name:'Home'})
            }
          } else {
            this.$nError(this.$t('account.messages.passwordNotUpdated'))
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.formPassword {
  width: 50%;
}
</style>